import Description from "../components/Description";
import Footer from "../components/Footer";
import JoinUs from "../components/JoinUs";
import HomeProjectsGallery from "../components/gallery/homeProjectsGallery/HomeProjectsGallery";
import { ImageGallery } from "../components/home/ImageGallery";
// import RammedInfo from "../components/home/RammedInfo";
import TopSection from "../components/home/TopSection";
import "../components/settings/style.css";

function Home() {
  return (
    <>
      <div className="container">
        <TopSection />
        <Description />
        <HomeProjectsGallery />
        {/* <RammedInfo /> */}
        <JoinUs />
        {/* <MakingProcess /> */}
        <ImageGallery />
        <Footer />
      </div>
    </>
  );
}

export default Home;
