import React from "react";
import TBHomeProjectsGallery from "../../photoGallery/TBHomeProjectsGallery";
import { FaInstagram } from "react-icons/fa";
import Image1 from "../../../assets/gallery/homeProjectsGallery/home-1.webp";
import Image2 from "../../../assets/gallery/homeProjectsGallery/home-2.webp";
import Image3 from "../../../assets/gallery/homeProjectsGallery/home-3.webp";
import Image4 from "../../../assets/gallery/homeProjectsGallery/home-4.webp";
import Image5 from "../../../assets/gallery/homeProjectsGallery/home-5.webp";
import Image6 from "../../../assets/gallery/homeProjectsGallery/home-6.webp";
import Image7 from "../../../assets/gallery/homeProjectsGallery/home-7.webp";
import Image8 from "../../../assets/gallery/homeProjectsGallery/home-8.webp";
import Image9 from "../../../assets/gallery/homeProjectsGallery/home-9.webp";
import Image10 from "../../../assets/gallery/homeProjectsGallery/home-10.webp";
import Image11 from "../../../assets/gallery/homeProjectsGallery/home-11.webp";
import Image12 from "../../../assets/gallery/homeProjectsGallery/home-12.webp";
import "./HomeProjectsGallery.css";

const HomeProjectsGallery = () => {
  const galleryImages = [
   
    {
      img: Image1,
    },
    {
      img: Image2,
    },
    {
      img: Image3,
    },
    {
      img: Image4,
    },
    {
      img: Image5,
    },
    {
      img: Image6,
    },
    {
      img: Image7,
    },
    {
      img: Image8,
    },
    {
      img: Image9,
    },
    {
      img: Image10,
    },
    {
      img: Image11,
    },
    {
      img: Image12,
    },
  ];
  return (
    <>
      <div className="home-projects">
      <br />
        <TBHomeProjectsGallery galleryImages={galleryImages} />
      <br />
      </div>
    </>
  );
};

export default HomeProjectsGallery;
