import React from 'react'
import ChrisCoelo from '../components/gallery/currentProjects/ChrisCoelo/ChrisCoelo'

export const ProjectChrisCoelo = () => {
  return (
    <div className="project-page">
        <ChrisCoelo />
    </div>
  )
}
