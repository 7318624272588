import "./TopSection.css";
import TerraTopSection from "../../assets/terraBrite/banner-1920.webp";
// import TerraTopSection from "../../assets/terraBrite/terra-web-banner.webp";
import TerraTopSectionMob from "../../assets/terraBrite/web-banner-mob.webp";
import TopSectionLogo from "../../assets/terraBrite/loguito3.png";

const TopSection = () => {
  return (
    <div className="home-top-section">
      <img
        className="top-banner"
        src={TerraTopSection}
        alt="Terra brite project"
      />
      <img
        className="top-banner-mob"
        src={TerraTopSectionMob}
        alt="Terra brite project"
      />
      <div className="home-top-text">
        <h1>Where Tradition Meets Sustainable&nbsp;Innovation</h1>
      </div>
      <div className="top-white-logo">
        <img src={TopSectionLogo} alt="Logo Terra Brite" />
      </div>
    </div>
  );
};

export default TopSection;
