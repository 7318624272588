import React from 'react'
import ApogeePHC from '../components/gallery/latestProjects/ApogeePHC/ApogeePHC'

export const ProjectApogeePHC = () => {
  return (
    <div className="project-page">
        <ApogeePHC />
    </div>
  )
}
