import React from "react";
import GiulioAndVictoria from "../components/gallery/latestProjects/GiulioAndVictoria/GiulioAndVictoria";

export const ProjectGiulioAndVictoria = () => {
  return (
    <div className="project-page">
      <GiulioAndVictoria />
    </div>
  );
};
