import React from "react";
import "./RammedInfo.css";
import RammedSmallFirst from "../../assets/rammedEarth/terra-small-1.webp";
import RammedSmallSecond from "../../assets/rammedEarth/terra-small-2.webp";
import RammedSmallThird from "../../assets/rammedEarth/terra-small-3.webp";
import RammedSmallFourth from "../../assets/rammedEarth/terra-small-4.webp";

const RammedInfo = () => {
  return (
    <div className="rammed-home">
      <div className="rammed-small">
        <div className="small-img">
          <img src={RammedSmallFirst} alt="Rammed earth bedroom" />
          <img src={RammedSmallSecond} alt="Rammed earth living" />
        </div>
        <div className="small-img">
          <img src={RammedSmallThird} alt="Rammed earth kitchen gray color" />
          <img src={RammedSmallFourth} alt="Rammed earth kitchen" />
        </div>
      </div>
      <div className="rammed-home-info">
        <h1>Why choose rammed earth?</h1>
        <p>
           Because it's not just a building material;
          it's a philosophy that embodies strength, durability, and
          environmental harmony. Our team of expert craftsmen takes pride in
          creating structures that seamlessly blend into the landscape,
          preserving the beauty of nature while providing comfort and
          functionality.
        </p>
        {/* <a className="rammed-info" href="http://terra-brite.com/rammed-earth">Read more</a> */}
      </div>
    </div>
  );
};

export default RammedInfo;
