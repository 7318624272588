import { useState } from "react";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaTimesCircle,
} from "react-icons/fa";

import "./TBHomeProjectsGallery.css";
// import { Icon } from "styled-icons/simple-icons";

const TBHomeProjectsGallery = ({ galleryImages }) => {
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  };

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(galleryImages.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  // Next Image
  const nextSlide = () => {
    slideNumber + 1 === galleryImages.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  return (
    <div>
      {openModal && (
        <div className="sliderWrap">
          <FaTimesCircle
            icon={FaTimesCircle}
            className="btnClose"
            onClick={handleCloseModal}
          />
          <FaChevronCircleLeft
            icon={FaChevronCircleLeft}
            className="btnPrev"
            onClick={prevSlide}
          />
          <FaChevronCircleRight
            icon={FaChevronCircleRight}
            className="btnNext"
            onClick={nextSlide}
          />
          <div className="fullScreenImage">
            <img src={galleryImages[slideNumber].img} alt="" />
          </div>
        </div>
      )}
      <div className="homeProjectGalleryWrap">
        {galleryImages &&
          galleryImages.map((slide, index) => {
            return (
              <div
                className="single"
                key={index}
                onClick={() => handleOpenModal(index)}
              >
                <img src={slide.img} alt="" />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TBHomeProjectsGallery;
