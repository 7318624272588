import React from "react";
import { FaChevronLeft } from "react-icons/fa";

export const ProjectDetails = () => {
  return (
    <div className="project-details">
    <a className="back-to-projects" href="/projects">
              <span><FaChevronLeft /></span>
      <p>
      Back to projects
      </p>
</a>
      <h1 className="project-title-info">South Beach Project</h1>
      <h2  className="work-description">Residential Interior remodeling</h2>
      <p className="work-details">Remodeling bathroom and kitchen remodeling. Fato su misura fabricated and installed the kitchen and vanities 
</p>
    </div>
  );
};
