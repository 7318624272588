import React from "react";
import { LatestProjects } from "../components/projectsSection/LatestProjects";
// import { CurrentProjects } from "../components/projectsSection/CurrentProjects";
import Footer from "../components/Footer";

export const Projects = () => {
  return (
  <div className="projects-section">
    <LatestProjects />
    {/* <CurrentProjects /> */}
    <Footer />
  </div>
  
  );
};
