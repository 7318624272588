import styled from "styled-components";

const DigitalB = styled.div`
  display: flex;
  margin: 0;
  width: 100%;
  background: #617657;
  padding: 30px 0;
  color: white;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1080px) {
    flex-direction: column;
    padding-bottom: 30px;
    line-height: 26px;
    font-size: 14px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 14px;
    line-height: 26px;
  }
  @media only screen and (max-width: 360px) {
    font-size: 12px;
  }
`;
const GlowCredits = styled.p`
  font-size: 18px;
  @media only screen and (max-width: 480px) {
    line-height: 26px;
    font-size: 14px;
  }
`;

const Divider = styled.p`
  font-size: 18px;
  margin-inline: 10px;
  @media only screen and (max-width: 1080px) {
    display: none;
  }
`;

const CopyGlow = styled.div`
  display: flex;
  justify-content: center;
`;

const DigitalBricks = () => {
  return (
    <DigitalB>
      <CopyGlow>
        <GlowCredits>
          {" "}
          &#xA9; Terra Brite todos los derechos reservados
        </GlowCredits>
      </CopyGlow>
      <Divider>|</Divider>
      <GlowCredits>Powered by Digital Bricks</GlowCredits>
    </DigitalB>
  );
};

export default DigitalBricks;
