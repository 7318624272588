import "../components/settings/style.css";
import Footer from "../components/Footer";
import { TeamSection } from "../components/about/TeamSection";



function About() {
  return (
    <>
      <div className="about-terra">
        <TeamSection />
        <Footer />
      </div>
    </>
  );
}

export default About;