import React from "react";
import TBHomeGallery from "../../photoGallery/TBHomeGallery";
import { FaInstagram } from "react-icons/fa";
import Image4 from "../../../assets/gallery/socialGallery/Redes4.webp";
import Image5 from "../../../assets/gallery/socialGallery/Redes5.webp";
import Image6 from "../../../assets/gallery/socialGallery/Redes6.webp";
import Image7 from "../../../assets/gallery/socialGallery/Redes8.webp";
import Image8 from "../../../assets/gallery/socialGallery/Redes7.webp";
import Image9 from "../../../assets/gallery/socialGallery/Redes9.webp";
import Image10 from "../../../assets/gallery/socialGallery/Redes11.webp";
import Image11 from "../../../assets/gallery/socialGallery/Redes10.webp";
import Image12 from "../../../assets/gallery/socialGallery/Redes12.webp";
import "./SocialGallery.css";

const SocialGallery = () => {
  const galleryImages = [
   
    {
      img: Image4,
    },
    {
      img: Image5,
    },
    {
      img: Image6,
    },
    {
      img: Image7,
    },
    {
      img: Image8,
    },
    {
      img: Image9,
    },
    {
      img: Image10,
    },
    {
      img: Image11,
    },
    {
      img: Image12,
    },
  ];
  return (
    <>
      <br />
      <div className="social-gallery-title">
        <a className="terra-instagram" href="https://www.instagram.com/terrabrite_sustainable/" target="_blank"> <span className="instagram-icon"><FaInstagram /></span><h2>Follow us</h2></a>
      </div>
      <br />
      <div>
        <TBHomeGallery galleryImages={galleryImages} />
      </div>
      <br />
    </>
  );
};

export default SocialGallery;
