import React from "react";
import "../components/settings/style.css";
import Footer from "../components/Footer";
import SustainableInfo from "../components/SustainableInfo";
import RammedInfo from "../components/home/RammedInfo";
import SustainableInfoTwo from "../components/SustainableInfoTwo";

const Sustainable = () => {
  return (
    <div className="container">
      <SustainableInfo />
      <RammedInfo />
      <SustainableInfoTwo />
      <Footer />
    </div>
  );
};

export default Sustainable;
