import React from "react";
import { FaChevronLeft } from "react-icons/fa";

export const ProjectDetails = () => {
  return (
    <div className="project-details">
      <a className="back-to-projects" href="/projects">
        <span>
          <FaChevronLeft />
        </span>
        <p>Back to projects</p>
      </a>
      <h1 className="project-title-info">
        Soledad Herrada - North Miami Beach
      </h1>
      <h2 className="work-description">Residential house expansion</h2>
      <p className="work-details">
        Addition of 500 sq ft to increase living room size, master bedroom size,
        new impact windows, new roof, reinforcing of existent structure, new
        finishes{" "}
      </p>
    </div>
  );
};
