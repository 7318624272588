import "./settings/style.css";
import "./Description.css";
import RammedInfo from "./home/RammedInfo";

const SustainableInfo = () => {
  return (
    <div className="terra-section terra-bg">
      <div className="main-info">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1>Sustainable construction. </h1>
        <span>
          Creating a positive, environmentally responsible and functional
          investment, we deliver any size of project providing premier customer
          service, quality building supplies and equipment.
        </span>
        <span>
          As advocates of sustainable living, we ensure that every project we
          undertake meets the highest standards of environmental responsibility.
        </span>

        <span>
          Rammed earth is an ancient construction technique we have refined
          implementing the latest in building technology and design while
          meeting a higher standard for performance and safety. Our passion for
          rammed earth knows no bounds and we are committed to harnessing its
          potential to shape a greener and more sustainable future for
          generations to come. <br />
        </span>
        <br />

          <h4>We are more than just builders. we are Terra Brite.</h4>
      
        {/* <br />
        <h2>Forthcoming in Florida: Rammed earth</h2>
        <p>
          Rammed earth is an ancient construction technique we have refined
          implementing the latest in building technology and design while
          meeting a higher standard for performance and safety. Our passion for
          rammed earth knows no bounds and we are committed to harnessing its
          potential to shape a greener and more sustainable future for
          generations to come. <br /> <br />
          <span>We are more than just builders. we are Terra Brite.</span>
        </p> */}
      </div>
    </div>
  );
};

export default SustainableInfo;
