import React from 'react'
import { AiOutlineWhatsApp } from 'react-icons/ai'
import "./WhatsApp.css"

export const WhatsApp = () => {
  return (
    <div className="whatsapp-static">
        <a href="https://wa.me/17864994493">
      <span><AiOutlineWhatsApp /></span>
  </a>
  </div>
  )
}
