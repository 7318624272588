import React from "react";
import NathanCohen from "../components/gallery/latestProjects/MarianoRetamal/MarianoRetamal";

export const ProjectMarianoRetamal = () => {
  return (
    <div className="project-page">
      <NathanCohen />
    </div>
  );
};
