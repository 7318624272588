import "./LatestProjects.css";
import Image1a from "../../assets/gallery/latestProjects/ApogeePHC/1.webp";
import Image1b from "../../assets/gallery/latestProjects/BrianLease/1b.webp";
import Image1 from "../../assets/gallery/latestProjects/RocioGoris/1.webp";
import Image2 from "../../assets/gallery/latestProjects/GiulioAndVictoria/1.webp";
import Image3 from "../../assets/gallery/latestProjects/MarianoRetamal/1.webp";
import Image4 from "../../assets/gallery/latestProjects/NathanCohen/1.webp";
import Image5 from "../../assets/gallery/latestProjects/SusanAndTico/1.webp";
import Image6 from "../../assets/gallery/latestProjects/Bathrooms/1.webp";

export const LatestProjects = () => {
  return (
    <div className="latest-projects">
      <div className="projects-header">
        <h1>Terra Brite - Latest Projects</h1>
        <p>
          We look forward to bring your project vision into our diverse
          construction industry experience!
        </p>
      </div>
      
      <div className="terra-projects">

      <div className="terra-b-project">
          <a
            className="project-gallery apogge-desk"
            href="/apogee-phc"
          >
            <img src={Image1a} alt="Apogee PHC Project" />
            <h2>Apogee PHC</h2>
          </a>
        </div>
        <div className="terra-b-project">
          <a
            className="project-gallery apogge-desk"
            href="/miami-beach-b-l"
          >
            <img src={Image1b} alt="Miami Beach Project" />
            <h2>Miami Beach</h2>
          </a>
        </div>
        </div>
      <div className="terra-projects">
        <div className="terra-b-project">
          <a
            className="project-gallery"
            href="/miami-shores-r-g"
          >
            <img src={Image1} alt="Miami Shores Project" />
            <h2>Miami Shores Project</h2>
          </a>
          {/* <p>description</p> */}
        </div>
        <div className="terra-b-project">
          <a
            className="project-gallery"
            href="/south-beach-g-v"
          >
            <img src={Image2} alt="South Beach Project" />
            <h2>South Beach Project</h2>
          </a>
          {/* <p>description</p> */}
        </div>
      </div>
      <div className="terra-projects">
        <div className="terra-b-project">
          <a
            className="project-gallery"
            href="/miami-beach-m-r"
          >
            <img src={Image3} alt="Miami Beach Project" />
            <h2>Miami Beach</h2>
          </a>
          {/* <p>description</p> */}
        </div>
        <div className="terra-b-project">
          <a
            className="project-gallery"
            href="/miami-beach-n-c"
          >
            <img src={Image4} alt="Miami Beach Project" />
            <h2>Miami Beach</h2>
          </a>
          {/* <p>description</p> */}
        </div>
      </div>
      <div className="terra-projects">
        <div className="terra-b-project">
          <a
            className="project-gallery"
            href="/kendall-s-t"
          >
            <img src={Image5} alt="kendall Project" />
            <h2>kendall</h2>
          </a>
          {/* <p>description</p> */}
        </div>
        <div className="terra-b-project">
          <a
            className="project-gallery"
            href="/bathrooms"
          >
            <img src={Image6} alt="Bathrooms" />
            <h2>Bathrooms</h2>
          </a>
          {/* <p>description</p> */}
        </div>
      </div>
    </div>
  );
};
