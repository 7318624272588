import React from "react";
import "./TeamCard.css";
import { FedeCard } from "./FedeCard";
import { VeroCard } from "./VeroCard";
import { AboutInfo } from "./AboutInfo";

export const TeamSection = () => {
  return (
    <div className="terra-about">
      <AboutInfo />
      <div className="team-section">
        <FedeCard />
        <VeroCard />
      </div>
    </div>
  );
};
