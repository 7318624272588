import Navbar from "./components/navbar/Navbar";
import "./components/settings/style.css";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Routes, Route } from "react-router-dom"
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import { Terra404 } from "./pages/Terra404";
import Services from "./pages/Services";
import { Projects } from "./pages/Projects";
import Sustainable from "./pages/Sustainable";
import { ProjectApogeePHC } from "./pages/ProjectApogeePHC"
import { ProjectRocioGoris } from "./pages/ProjectRocioGoris"
import { ProjectGiulioAndVictoria } from "./pages/ProjectGiulioAndVictoria"
import { ProjectSusanAndTico } from "./pages/ProjectSusanAndTico"
import { ProjectNathanCohen } from "./pages/ProjectNathanCohen"
import { ProjectMarianoRetamal } from "./pages/ProjectMarianoRetamal"
import { ProjectBrianLease } from "./pages/ProjectBrianLease"
import { ProjectBathrooms } from "./pages/ProjectBathrooms"
import { ProjectSoledadHerrada } from "./pages/ProjectSoledadHerrada"
import { ProjectGiancarloCoco } from "./pages/ProjectGiancarloCoco"
import { ProjectChrisCoelo } from "./pages/ProjectChrisCoelo"
import { ProjectThomAndPulian } from "./pages/ProjectThomAndPulian"
import { WhatsApp } from "./components/whatsApp/WhatsApp";



const App = () => {
  return (
    <>
      <Navbar />
      <WhatsApp />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/sustainable" element={<Sustainable />} />
        <Route path="/apogee-phc" element={<ProjectApogeePHC />} />
        <Route path="/miami-shores-r-g" element={<ProjectRocioGoris />} />
        <Route path="/south-beach-g-v" element={<ProjectGiulioAndVictoria />} />
        <Route path="/miami-beach-n-c" element={<ProjectNathanCohen />} />
        <Route path="/kendall-s-t" element={<ProjectSusanAndTico />} />
        <Route path="/miami-beach-m-r" element={<ProjectMarianoRetamal />} />
        <Route path="/miami-beach-b-l" element={<ProjectBrianLease />} />
        <Route path="/bathrooms" element={<ProjectBathrooms />} />
        <Route path="/soledad-herrada" element={<ProjectSoledadHerrada />} />
        <Route path="/giancarlo-coco" element={<ProjectGiancarloCoco />} />
        <Route path="/chris-coelo" element={<ProjectChrisCoelo />} />
        <Route path="/thom-and-pulian" element={<ProjectThomAndPulian />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="*" element={<Terra404 />} />
      </Routes>
    </>
  );
};

export default App;