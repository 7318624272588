import React from "react";
import SusanAndTico from "../components/gallery/latestProjects/SusanAndTico/SusanAndTico";

export const ProjectSusanAndTico = () => {
  return (
    <div className="project-page">
      <SusanAndTico />
    </div>
  );
};
