import React from "react";
import NathanCohen from "../components/gallery/latestProjects/NathanCohen/NathanCohen";

export const ProjectNathanCohen = () => {
  return (
    <div className="project-page">
      <NathanCohen />
    </div>
  );
};
