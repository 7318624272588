import React from "react";
import BrianLease from "../components/gallery/latestProjects/BrianLease/BrianLease";

export const ProjectBrianLease = () => {
  return (
    <div className="project-page">
      <BrianLease />
    </div>
  );
};
