import React from "react";
import { FaChevronLeft } from "react-icons/fa";

export const ProjectDetails = () => {
  return (
    <div className="project-details">
      <a className="back-to-projects" href="/projects">
        <span>
          <FaChevronLeft />
        </span>
        <p>Back to projects</p>
      </a>
      <h1 className="project-title-info">Chris Coelo - Kendall</h1>
      <h2 className="work-description">
        Addition of 800 sq ft to the house for new garage.
      </h2>
      <p className="work-details">
        New foundations, new slabs, new exterior walls, new roof, new impact
        windows, new finishes and doors.
      </p>
    </div>
  );
};
