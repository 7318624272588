import React from 'react'
import "./AboutInfo.css"
export const AboutInfo = () => {
  return (
    <div className='about-info'>
        <h1>About Terra Brite</h1>
      <p>
        <span>
          Welcome to our owned family sustainable construction company,
        </span>{" "}
        where we bring your eco-conscious dreams to life through the timeless
        art of rammed earth building. Prepare to embark on a journey that
        intertwines innovation with nature, creating structures that stand the
        test of time while leaving a minimal footprint on our precious planet.
      </p>

      <p>
        When you partner with Terra Brite, you're not just getting a building –
        you're embracing a lifestyle. Our dedication to sustainability goes
        beyond construction; it's about fostering a sense of community that
        cherishes and protects our environment.
      </p>
      <p>
        Join us on this extraordinary journey towards a greener tomorrow.
        Together, we can create a world where architecture and nature unite,
        forever leaving a positive mark on our planet. Are you ready to build
        your sustainable legacy?<span> Let's begin! </span>
      </p>
    </div>
  )
}
