import React from 'react'
import Bathrooms from '../components/gallery/latestProjects/Bathrooms/Bathrooms'

export const ProjectBathrooms = () => {
  return (
    <div className="project-page">
        <Bathrooms />
    </div>
  )
}
