import React from 'react'
import SoledadHerrada from '../components/gallery/currentProjects/SoledadHerrada/SoledadHerrada'

export const ProjectSoledadHerrada = () => {
  return (
    <div className="project-page">
        <SoledadHerrada />
    </div>
  )
}
