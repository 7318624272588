import React from 'react'
import "../components/settings/style.css";
import { ServicesList } from '../components/services/ServicesList';
import Footer from '../components/Footer';

const Services = () => {
  return (
    <div className="container">
<ServicesList />
<Footer />
    </div>
  )
}

export default Services