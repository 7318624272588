import React from "react";
import "./TeamCard.css";
import FedeTeam from "../../assets/team/fede-team.webp";
export const FedeCard = () => {
  return (
    <div className="fede-card">
      <div className="fede-info">
        <img className="member-image" src={FedeTeam} alt="Fede Terra Brite President" />
        <p className="member-info"><span className="member-name">Federico Godoy - </span> 
        CEO</p>
      </div>
    </div>
  );
};
