import React from "react";
import RocioGoris from "../components/gallery/latestProjects/RocioGoris/RocioGoris";

export const ProjectRocioGoris = () => {
  return (
    <div className="project-page">
      <RocioGoris />
    </div>
  );
};
