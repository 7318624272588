import Footer from "../components/Footer";
import { ContactForm } from "../components/contact/ContactForm";
import { ContactInfo } from "../components/contact/ContactInfo";
import "../components/settings/style.css";



const ContactUs = () => {

    return (
        <div className="contact-page">
            <ContactInfo />
            <ContactForm />
            <Footer />
        </div>
    );
};

export default ContactUs;