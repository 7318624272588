import React from "react";
import TBGallery from "../../../photoGallery/TBGallery";
import Image2 from "../../../../assets/gallery/latestProjects/Bathrooms/2.webp";
import Image3 from "../../../../assets/gallery/latestProjects/Bathrooms/3.webp";
import Image4 from "../../../../assets/gallery/latestProjects/Bathrooms/4.webp";
import Image5 from "../../../../assets/gallery/latestProjects/Bathrooms/5.webp";
import Image6 from "../../../../assets/gallery/latestProjects/Bathrooms/6.webp";
import Image7 from "../../../../assets/gallery/latestProjects/Bathrooms/7.webp";
import Image8 from "../../../../assets/gallery/latestProjects/Bathrooms/8.webp";
import Image9 from "../../../../assets/gallery/latestProjects/Bathrooms/9.webp";
import Image10 from "../../../../assets/gallery/latestProjects/Bathrooms/10.webp";

import { ProjectDetails } from "./ProjectDetails";
import "../../projectStyling/style.css";
import Footer from "../../../Footer";
import TBGalleryTwo from "../../../photoGallery/TBGalleryTwo";

const Bathrooms = () => {
  const galleryImages = [
    {
      img: Image2,
    },
    {
      img: Image3,
    },
    {
      img: Image4,
    },

  ];
  const galleryImagesTwo = [
    {
      img: Image5,
    },
    {
      img: Image6,
    },
    {
      img: Image7,
    },
    {
      img: Image8,
    },
    {
      img: Image9,
    },
    {
      img: Image10,
    },

  ];
  return (
    <>
      <br />
      <div>
        <ProjectDetails />
      </div>
      <br />
      <div>
        <TBGallery galleryImages={galleryImages}/>
      </div>
      <div>
        <TBGalleryTwo galleryImagesTwo={galleryImagesTwo} />
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Bathrooms;
