import React from "react";
import TBGallery from "../../../photoGallery/TBGallery";
import Image2 from "../../../../assets/gallery/latestProjects/MarianoRetamal/2.webp";
import Image3 from "../../../../assets/gallery/latestProjects/MarianoRetamal/3.webp";
import Image4 from "../../../../assets/gallery/latestProjects/MarianoRetamal/4.webp";
import { ProjectDetails } from "./ProjectDetails";
import "../../projectStyling/style.css"
import Footer from "../../../Footer";

const MarianoRetamal = () => {
  const galleryImages = [
    {
      img: Image2,
    },
    {
      img: Image3,
    },
    {
      img: Image4,
    },
  ];
  return (
    <>
      <br />
      <div>
        <ProjectDetails />
      </div>
      <br />
      <div>
        <TBGallery galleryImages={galleryImages} />
      </div>
      <div><Footer /></div>
    </>
  );
};

export default MarianoRetamal;
