import React from "react";
import { FaChevronLeft } from "react-icons/fa";

export const ProjectDetails = () => {
  return (
    <div className="project-details">
    <a className="back-to-projects" href="/projects">
              <span><FaChevronLeft /></span>
      <p>
      Back to projects
      </p>
</a>
      <h1 className="project-title-info">Miami Shores Project</h1>
      <h2  className="work-description">Residential Interior house remodel</h2>
      <p className="work-details">New layout floor plan that includes addition of two bathrooms, new plumbing, new electrical, new kitchen and finishes.</p>
    </div>
  );
};
