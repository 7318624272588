import "./settings/style.css";
import "./JoinUs.css";

const JoinUs = () => {
  return (
    <div className="terra-section">
      <div className="join-info">
        {/* <h1>Pioneers in the realm of sustainable construction. </h1> */}
        <p> <span>Join&nbsp;us 
          on this extraordinary journey towards a greener tomorrow.</span></p>
        <p>
         Together, we
          can create a world where architecture and nature unite, forever
          leaving a positive mark on our planet.<br />Are you ready to build your
          sustainable legacy? Let's begin!
        </p>
      </div>
    </div>
  );
};

export default JoinUs;
