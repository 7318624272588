import React from "react";
import TBGallery from "../../../photoGallery/TBGallery";
import Image1 from "../../../../assets/gallery/currentProjects/SoledadHerrada/1.webp";
import Image2 from "../../../../assets/gallery/currentProjects/SoledadHerrada/2.webp";
import Image3 from "../../../../assets/gallery/currentProjects/SoledadHerrada/3.webp";
import Image4 from "../../../../assets/gallery/currentProjects/SoledadHerrada/4.webp";
import Image5 from "../../../../assets/gallery/currentProjects/SoledadHerrada/5.webp";
import Image6 from "../../../../assets/gallery/currentProjects/SoledadHerrada/6.webp";
import Image7 from "../../../../assets/gallery/currentProjects/SoledadHerrada/7.webp";
import Image8 from "../../../../assets/gallery/currentProjects/SoledadHerrada/8.webp";
import Image9 from "../../../../assets/gallery/currentProjects/SoledadHerrada/9.webp";
import { ProjectDetails } from "./ProjectDetails";
import "../../projectStyling/style.css"
import Footer from "../../../Footer";

const SoledadHerrada = () => {
  const galleryImages = [
    {
      img: Image1,
    },
    {
      img: Image2,
    },
    {
      img: Image3,
    },
    {
      img: Image4,
    },
    {
      img: Image5,
    },
    {
      img: Image6,
    },
    {
      img: Image7,
    },
    {
      img: Image8,
    },
    {
      img: Image9,
    },
  ];
  return (
    <>
      <br />
      <div>
        <ProjectDetails />
      </div>
      <br />
      <div>
        <TBGallery galleryImages={galleryImages} />
      </div>
      <div><Footer /></div>
    </>
  );
};

export default SoledadHerrada;
