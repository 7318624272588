import React from "react";
import { FaChevronLeft } from "react-icons/fa";

export const ProjectDetails = () => {
  return (
    <div className="project-details">
    <a className="back-to-projects" href="/projects">
              <span><FaChevronLeft /></span>
      <p>
      Back to projects
      </p>
</a>
      <h1 className="project-title-info">Kendall Project</h1>
      <h2  className="work-description">Residential property work</h2>
      <p className="work-details">Interior layout alterations that includes new kitchen, new bathrooms, new doors, new floors, new plumbing and electrical work. 
</p>
    </div>
  );
};
