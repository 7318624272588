import React from 'react'
import "./ContactForm.css"

export const ContactForm = () => {
  return (
    <div className="contact-form">
        <iframe src="https://digitalbricks.com.ar/terra-brite/contact-us.html" frameborder="0"></iframe>
    </div>
  )
}
