import React from "react";
import "./ContactInfo.css";
import Logo from "../../assets/terraBrite/icon-w.webp"

export const ContactInfo = () => {
  return (
    <div className="contact-info">
      <div className="contact-header">
        <h1>Get a start on your project</h1>
        <p>Terra Brite sustainable construction is&nbsp;independently&nbsp;owned</p>
      </div>
      <div className="contact-details">
        <div className="contact-terra">
          <div className="terra-details">
            <h3 className="phone-n">&nbsp; Phone number:</h3>
            <a href="tell:+17864994493">
              <span className="contact-icon"> </span>+1 (786)499-4493
            </a>
          </div>
        </div>
        <div className="contact-terra">
          <div className="terra-details">
            <h3 className="email-a">Email address:</h3>
            <a href="mailto:info@terra-brite.com">
              <span className="contact-icon"> </span>info@terra-brite.com
            </a>
          </div>
        </div>
      </div>
        <div className="contact-logo">
            <img src={Logo} alt="" />
        </div>
    </div>
  );
};
