import React from 'react'
import "./ServicesList.css"
export const ServicesList = () => {
  return (
    <div className="terra-services">
        <h1>Terra Brite - Services</h1>
        <div className="service-group">
<div className="terra-service">
    <span>New Construction:</span> <p>Expertise in building new structures from the ground up.</p>
   </div>
   <div className="terra-service">
   <span>Remodeling and Renovations:</span> <p> Transforming existing spaces into modern, functional areas.</p>
    </div>
    </div>
    <div className="service-group">
    <div className="terra-service">
    <span>Commercial Construction:</span><p> Specialized services for commercial projects and businesses.</p>
   </div>
   <div className="terra-service">
   <span>Residential Construction:</span> <p> Tailored solutions for homes and residential properties.</p>
    </div>
    </div>
    <div className="service-group">
    <div className="terra-service">
    <span>Design-Build:</span> <p>Streamlined design and construction under one roof for efficiency.</p>
   </div>
   <div className="terra-service">
   <span>Interior Fit-Out:</span> <p> Creating interior spaces that match your vision and needs.</p>
</div>
</div>
<div className="service-group">
<div className="terra-service">
<span>Custom Home Building:</span><p> Crafting unique, personalized homes for clients.</p>
   </div>
   <div className="terra-service">
   <span>Green Building and Sustainability:</span> <p> Implementing eco-friendly and sustainable construction practices.</p>
    </div>
    </div>
    <div className="service-group">
    <div className="terra-service">
    <span>Project Management:</span><p> Comprehensive project planning and execution.</p>
    </div>
    <div className="terra-service">
    <span>Site Development:</span> <p>Preparing and optimizing construction sites for work.</p>
    </div>
    </div>
    <div className="service-group">
    <div className="terra-service">
    <span>Foundation and Framing:</span><p> Solid foundations and structural framing for stability.</p>
   </div>
   <div className="terra-service">
   <span>Roofing and Exterior Finishes:</span>  <p>Ensuring durability and aesthetic appeal.</p>
    </div>
    </div>
    <div className="service-group">
    <div className="terra-service">
    <span>Electrical and Plumbing:</span><p> Expertise in essential building systems.</p>
    </div>
    <div className="terra-service">
    <span>HVAC Installation:</span><p> Climate control solutions for comfort.</p>
    </div>
    </div>
    <div className="service-group">
    <div className="terra-service">
    <span>Flooring and Finishing:</span><p> Quality flooring and interior finishing touches.</p>
    </div>
    <div className="terra-service">
    <span>Permit Procurement:</span><p> Handling permits and compliance with local regulations.</p>
</div>
</div>
</div>
  )
}
