import React from 'react'
import "./TeamCard.css"
import VeroTeam from "../../assets/team/vero-team.webp"


export const VeroCard = () => {
  return (
    <div className="vero-card">
        <div className="vero-info">
            <img src={VeroTeam} alt="Vero Terra Brite CEO" />
            <p><span>Veronica Godoy - </span>
            Office manager</p>
        </div>
    </div>
  )
}
