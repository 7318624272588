import React from 'react'
import ThomAndPulian from '../components/gallery/currentProjects/ThomAndPulian/ThomAndPulian'

export const ProjectThomAndPulian = () => {
  return (
    <div className="project-page">
        <ThomAndPulian />
    </div>
  )
}
