import Logo from "../assets/terraBrite/terra-brite-logo-w.webp";
import DigitalBricks from "./DigitalBricks";
import "./Footer.css";
import {
  AiOutlineMail,
  AiOutlineEnvironment,
  AiOutlineWhatsApp,
  AiOutlineInstagram,
} from "react-icons/ai";

const Footer = () => {
  return (
    <>
      <div className="footer-container">
      <div className="footer-col logo-col-mob">
          <img src={Logo} alt="Terra Brite Logo" />
        </div>
        <div className="footer-col first-col">
          <div className="first-col">
          <a href="https://wa.me/17864994493">
            <span>
              <AiOutlineWhatsApp />
            </span>
            (786) 499-4493
          </a>
          <a href="/" target="_self">
            <span>
              <AiOutlineEnvironment />
            </span>
            15325 NW 1st St, P. Prines, FL 33028
          </a>
          <a href="emailto:info@terra-brite.com">
            <span>
              <AiOutlineMail />
            </span>
            info@terra-brite.com
          </a></div>
        </div>
        <div className="footer-col logo-col">
          <img src={Logo} alt="Terra Brite Logo" />
        </div>
        <div className="footer-col">
          <div className="last-col">
            <h1>Building</h1>
            <h2>with a</h2>
            <h1>purpose</h1>
          </div>
        </div>
      </div>
      <DigitalBricks />
    </>
  );
};

export default Footer;
