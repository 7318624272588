import React from "react";
import TBGallery from "../../../photoGallery/TBGallery";
import Image2 from "../../../../assets/gallery/latestProjects/SusanAndTico/2.webp";
import Image3 from "../../../../assets/gallery/latestProjects/SusanAndTico/3.webp";
import Image4 from "../../../../assets/gallery/latestProjects/SusanAndTico/4.webp";
import Image5 from "../../../../assets/gallery/latestProjects/SusanAndTico/5.webp";
import Image6 from "../../../../assets/gallery/latestProjects/SusanAndTico/6.webp";
import Image7 from "../../../../assets/gallery/latestProjects/SusanAndTico/7.webp";
import Image8 from "../../../../assets/gallery/latestProjects/SusanAndTico/8.webp";
import Image9 from "../../../../assets/gallery/latestProjects/SusanAndTico/9.webp";
import Image10 from "../../../../assets/gallery/latestProjects/SusanAndTico/10.webp";
import Image11 from "../../../../assets/gallery/latestProjects/SusanAndTico/11.webp";
import Image12 from "../../../../assets/gallery/latestProjects/SusanAndTico/12.webp";
import Image13 from "../../../../assets/gallery/latestProjects/SusanAndTico/13.webp";
import { ProjectDetails } from "./ProjectDetails";
import "../../projectStyling/style.css";
import Footer from "../../../Footer";
import TBGalleryTwo from "../../../photoGallery/TBGalleryTwo";

const SusanAndTico = () => {
  const galleryImages = [
    {
      img: Image5,
    },
    {
      img: Image6,
    },
    {
      img: Image10,
    },
    {
      img: Image11,
    },
    {
      img: Image12,
    },
    {
      img: Image13,
    },
  ];
  const galleryImagesTwo = [
    {
      img: Image2,
    },
    {
      img: Image3,
    },
    {
      img: Image4,
    },
    {
      img: Image7,
    },
    {
      img: Image8,
    },
    {
      img: Image9,
    },

  ];
  return (
    <>
      <br />
      <div>
        <ProjectDetails />
      </div>
      <br />
      <div>
        <TBGallery galleryImages={galleryImages}/>
      </div>
      <div>
        <TBGalleryTwo galleryImagesTwo={galleryImagesTwo} />
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default SusanAndTico;
