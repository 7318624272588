import React from 'react'
import GiancarloCoco from '../components/gallery/currentProjects/GiancarloCoco/GiancarloCoco'

export const ProjectGiancarloCoco = () => {
  return (
    <div className="project-page">
        <GiancarloCoco />
    </div>
  )
}
