import "../components/settings/style.css";


export const Terra404 = () => {
  return (
    <div id="error-page">
      <h1>404</h1>
      <p>La página que buscás no existe</p>
      <p>
        Por favor verifica el enlace
      </p>
    </div>  
    )
}
