import "./settings/style.css";
import "./Description.css";
import RammedInfo from "./home/RammedInfo";

const SustainableInfoTwo = () => {
  return (
    <div className="terra-section terra-bg">
      <div className="main-info">


        {/* <p>
          Rammed earth is an ancient construction technique we have refined
          implementing the latest in building technology and design while
          meeting a higher standard for performance and safety. Our passion for
          rammed earth knows no bounds and we are committed to harnessing its
          potential to shape a greener and more sustainable future for
          generations to come. <br />
          We are more than just builders. we are Terra Brite.
        </p>
       */}

      </div>
    </div>
  );
};

export default SustainableInfoTwo;
