import "./settings/style.css";
import "./Description.css";

const Description = () => {
  return (
    <div className="terra-section terra-bg">
      <div className="main-info">
        <h1 className="desk-h1">Licensed General Contractors - CGC1529804</h1>
        <h1 className="mob-h1">Licensed&nbsp;General&nbsp;Contractors CGC1529804</h1>
        {/* <h1>Pioneers in the realm of sustainable construction. </h1> */}
        {/* <p>
          Creating a positive, environmentally responsible and functional
          investment, we deliver any size of project providing premier customer
          service, quality building supplies and equipment.
        </p>
        <p>
          As advocates of sustainable living, we ensure that every project we
          undertake meets the highest standards of environmental responsibility.
        </p> */}
        <p>
          When you partner with Terra Brite, you're not just getting a building,
          you're embracing a lifestyle. Our dedication to sustainability goes
          beyond construction; it's about fostering a sense of community that
          cherishes and protects our environment.
        </p>
        <p>We are more than just builders, we are Terra Brite.</p>
        {/* <p>
          Rammed earth is an ancient construction technique we have refined
          implementing the latest in building technology and design while
          meeting a higher standard for performance and safety. Our passion for
          rammed earth knows no bounds and we are committed to harnessing its
          potential to shape a greener and more sustainable future for
          generations to come. <br />
          We are more than just builders. we are Terra Brite.
        </p> */}
        <br />
        <h2>New projects, contruction additions, remodeling&nbsp;|&nbsp;repairs</h2>
        <p>
          South Florida homeowners turn to Terra Brite on every assignment due
          to our commitment delivering quality service to our clients through
          the entire process by keeping them up-to-date on the project status,
          meeting deadlines and coordinating with other related projects.
        </p>

        <span>
          Need additional living space that truly accommodates the needs of your
          growing family?
        </span>
        <p>
          {" "}
          Awesome, expanding your home's usable square footage will also
          increase the value of your property.
          <br />
          <br />
          From building a new home to construction additions, remodeling or
          repair services. We look forward to bring your project vision into our
          diverse construction industry experience!
        </p>
        {/* <br />
        <h2>Forthcoming in Florida: Rammed earth</h2>
        <p>
          Rammed earth is an ancient construction technique we have refined
          implementing the latest in building technology and design while
          meeting a higher standard for performance and safety. Our passion for
          rammed earth knows no bounds and we are committed to harnessing its
          potential to shape a greener and more sustainable future for
          generations to come. <br /> <br />
          <span>We are more than just builders. we are Terra Brite.</span>
        </p> */}
      </div>
    </div>
  );
};

export default Description;
