import "./Navbar.css";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import Logo from '../../assets/terraBrite/terra-brite-logo.webp';


const Navbar = () => {
  const [click, setClick] = useState(false)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)


      const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setColorchange(true);
        }
        else {
            setColorchange(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);
  return (
    <>
    <IconContext.Provider value={{color:"#fff"}} >
    <nav className={colorChange ? 'navbar colorChange' : 'navbar'}>
      <div className="navbar-container nav-container">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src={Logo} alt="Logo de Glow consultora" className="glow-logo" />
        </Link>
        <div className="menu-icon" onClick={handleClick} >
          {click ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <NavLink to="/" className={({ isActive }) => "nav-links" + (isActive ? " activated " : "")} onClick={closeMobileMenu}>Home</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/services" className={({ isActive }) => "nav-links" + (isActive ? " activated " : "")} onClick={closeMobileMenu}>Services</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/projects" className={({ isActive }) => "nav-links" + (isActive ? " activated " : "")} onClick={closeMobileMenu}>Projects</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/sustainable" className={({ isActive }) => "nav-links" + (isActive ? " activated " : "")} onClick={closeMobileMenu}>Sustainable</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/about-us" className={({ isActive }) => "nav-links" + (isActive ? " activated " : "")} onClick={closeMobileMenu}>About&nbsp;us</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/contact-us" className={({ isActive }) => "nav-links" + (isActive ? " activated " : "")} onClick={closeMobileMenu}>Contact&nbsp;us</NavLink>
          </li>
        </ul>
      </div>
    </nav>
    </IconContext.Provider>
    </>
  )
}

export default Navbar;