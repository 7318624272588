import React from "react";
import { FaChevronLeft } from "react-icons/fa";

export const ProjectDetails = () => {
  return (
    <div className="project-details">
      <a className="back-to-projects" href="/projects">
        <span>
          <FaChevronLeft />
        </span>
        <p>Back to projects</p>
      </a>
      <h1 className="project-title-info">Giancarlo Coco - Miami Beach</h1>
      <h2 className="work-description">Residential interior remodel</h2>
      <p className="work-details">
        Kitchen, bathrooms and flooring. New finishes Fato su misura are
        fabricating and installing the closets, vanities and kitchen
      </p>
    </div>
  );
};
