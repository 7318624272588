import React from "react";
import { FaChevronLeft } from "react-icons/fa";

export const ProjectDetails = () => {
  return (
    <div className="project-details">
    <a className="back-to-projects" href="/projects">
              <span><FaChevronLeft /></span>
      <p>
      Back to projects
      </p>
</a>
      <h1 className="project-title-info">Apogee PHC - South Beach</h1>
      <h2  className="work-description">Residential exterior work</h2>
      <p className="work-details">Floor mosaics and marble slabs installation, and 
 wood deck refinishing</p>
    </div>
  );
};
