import React from "react";
import "./ImageGallery.css";
import SocialGallery from "../gallery/socialGallery/SocialGallery";

export const ImageGallery = () => {
  return (
    <div className="social-gallery">
      <div>
        <SocialGallery />
      </div>
    </div>
  );
};
